import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Box, Container, Layout, Markdown } from '../components'

interface TermsTemplateProps {
  seoTitle: string
  metaDescription: string
  markdown: string
}

const TermsPageTemplate: React.FC<TermsTemplateProps> = ({
  seoTitle,
  metaDescription,
  markdown
}) => {
  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Box py="100">
        <Container size="small">
          <Markdown>{markdown}</Markdown>
        </Container>
      </Box>
    </>
  )
}

interface TermsPageProps {
  data: {
    markdownRemark: {
      frontmatter: TermsTemplateProps
    }
  }
}

const TermsPage: React.FC<TermsPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TermsPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query TermsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "terms" } }) {
      frontmatter {
        seoTitle
        metaDescription
        markdown
      }
    }
  }
`

export { TermsPageTemplate }
export default TermsPage
